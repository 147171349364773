@import "~antd/dist/antd.less";

@alert-red: "#C62828";

h1 {
  font-size: 26px;
}

.ant-layout {
  background: none;
}

.ant-layout-sider {
  background: none;
}

.ant-empty-normal {
  margin: 10px 0;
}

.ant-empty-normal {
  .ant-empty-image {
    height: 32px;
  }
}

.login {
  .ant-tabs {
    color: #8a68c999;
  }

  .ant-tabs-bottom > .ant-tabs-nav::before,
  .ant-tabs-bottom > div > .ant-tabs-nav::before,
  .ant-tabs-top > .ant-tabs-nav::before,
  .ant-tabs-top > div > .ant-tabs-nav::before {
    border-bottom: 1px solid #ffffff33;
  }

  .button-login-fill {
    position: absolute;
    background-image: linear-gradient(to right, #e040fb, #00bcd4);
    background-size: 150% 150%;
    height: 40px;
    width: 100%;
    border-radius: 5px;
    margin: -32px 0 0 -15px;
    transition: all 0.4s ease;
    pointer-events: none;
    z-index: -1;
  }

  .button-login:hover .button-login-fill {
    -webkit-animation: gradient 3s ease infinite;
    -moz-animation: gradient 3s ease infinite;
    animation: gradient 3s ease infinite;
    pointer-events: none;
  }
}

.password-reset,
.free-plan-limit,
.cluster {
  .button-login-fill {
    position: absolute;
    background-image: linear-gradient(to right, #e040fb, #00bcd4);
    background-size: 150% 150%;
    height: 40px;
    width: 100%;
    border-radius: 5px;
    margin: -33px 0 0 -15px;
    transition: all 0.4s ease;
    pointer-events: none;
    z-index: -1;
  }

  .button-login:hover .button-login-fill {
    -webkit-animation: gradient 3s ease infinite;
    -moz-animation: gradient 3s ease infinite;
    animation: gradient 3s ease infinite;
    pointer-events: none;
  }
}

.cluster {
  .glow {
    border-radius: 8px;
    -webkit-animation: glowing 1300ms ease infinite;
    -moz-animation: glowing 1300ms ease infinite;
    animation: glowing 1300ms infinite;
  }
}

.plans {
  .ant-card-bordered {
    border: 0px;
  }

  .description {
    height: 330px;

    p {
      font-size: 16px;
      line-height: 18px;
    }
  }
}

.cluster {
  .ant-card-bordered {
    border: 1px solid #826fdf66;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
      rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    border-radius: 10px;
    overflow: hidden;
  }
}

.cluster-card {
  .ant-card-cover {
    height: 110px;
  }

  .render-processing {
    .ant-spin-blur::after {
      opacity: 0;
    }
  }

  .ant-card-actions {
    background: transparent;
    border-top: 0;

    li {
      margin: 0;
    }
  }
}

.dedicated-clusters {
  .free {
    background-color: #c8e6c933;
  }
}

.animated-gradient {
  background: linear-gradient(45deg, #6ac0df, #87e2cc, #8a68c9);
  background-size: 600% 600%;
  animation: GradientBackground 10s ease infinite;
  transition: box-shadow 0.3s, border-color 0.3s;
}
.animated-gradient:hover {
  box-shadow: 0 1px 2px -2px rgba(0, 0, 0, 0.16),
    0 3px 6px 0 rgba(0, 0, 0, 0.12), 0 5px 12px 4px rgba(0, 0, 0, 0.09);
}

@keyframes GradientBackground {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@-webkit-keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@-moz-keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@-webkit-keyframes gradient {
  0% {
    background-color: #ffffff;
    box-shadow: 0 0 5px #00bcd4;
  }
  50% {
    background-color: #ffffff;
    box-shadow: 0 0 20px #00bcd4;
  }
  100% {
    background-color: #ffffff;
    box-shadow: 0 0 5px #00bcd4;
  }
}

@-moz-keyframes gradient {
  0% {
    background-color: #ffffff;
    box-shadow: 0 0 5px #00bcd4;
  }
  50% {
    background-color: #ffffff;
    box-shadow: 0 0 20px #00bcd4;
  }
  100% {
    background-color: #ffffff;
    box-shadow: 0 0 5px #00bcd4;
  }
}

@keyframes glowing {
  0% {
    background-color: #ffffff;
    box-shadow: 0 0 5px #00bcd4;
  }
  50% {
    background-color: #ffffff;
    box-shadow: 0 0 20px #00bcd4;
  }
  100% {
    background-color: #ffffff;
    box-shadow: 0 0 5px #00bcd4;
  }
}

@primary-color: #8A68C9;